(function ($, TweenLite, App, window) {
  /**
   * Click handler for the back to top button
   * @param  {Event} e The event's object
   * @return {void}
   */
  function clickHandler(e) {
    e.preventDefault();
    TweenLite.to(window, 1, {
      scrollTo: {
        y: 0,
        autoKill: App.is.iOS,
      },
      ease: window.Expo.easeInOut,
    });
  }

  // Bind event handler
  $('#js-back-to-top').on('click', clickHandler);
})(jQuery, TweenLite, App, window);
